// 変数のカスタマイズ
@import './foundation/variables';

// Bootstrap必要モジュールのインポート
@import './foundation/bootstrap';

// 独自関数のインポート
@import './foundation/functions';
@import './foundation/mixins';

// webfont
$fa-font-path: "../fonts/fontawesome";
@import './foundation/fonts/fontawesome/fontawesome';
@import './foundation/fonts/fontawesome/solid';
@import './foundation/fonts/fontawesome/regular';
@import './foundation/fonts/fontawesome/brands';
@import './foundation/fonts/Lato';
@import './foundation/fonts/Roboto';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');


// import Swiper styles
@import '../../node_modules/swiper/swiper-bundle.min';

// layout
@import './layout/one-column';
// @import './layout/two-column';

// components
@import 'components/title';
@import 'components/button';
@import 'components/typography';
@import 'components/itemlist';

// elements
@import 'elements/header';
@import 'elements/gnav';
@import 'elements/footer';
@import 'elements/fixnav';
@import 'elements/mainvisual';
@import 'elements/breadcrumbs';
@import 'elements/pagination';
@import 'elements/moduletitle';
@import 'elements/list';
@import 'elements/detail';
@import 'elements/comment';

// blocks
@import 'blocks/concept';
@import 'blocks/contact';
@import 'blocks/contents';
@import 'blocks/banner';
@import 'blocks/freearea';


// plugin
@import 'plugins/lazydisp';
@import 'plugins/pageload';
@import 'plugins/editor_template_custom';
@import '../../node_modules/lity/dist/lity.min';

// pages
@import 'pages/profile';
@import 'pages/privacy';
@import 'pages/sitemap';
@import 'pages/search';
@import 'pages/shopguide';
@import 'pages/form';
