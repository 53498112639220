.is--home .rwd002-container {
  margin-top: 0;
  overflow: hidden;
}

.is--home .rwd002-block-freearea {
  &:first-child{
    margin-top: 0;
  }
  &__inner{
    max-width: 100%;
  }
}

.fixed-sns {
  position: fixed;
  top: calc(50% - 5.6em);
  right: map-get($spacers, 3);
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;
  transition: $transition-base;
  @include media-down(lg) {
    display: none;
  }
  &__title {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    transform-origin: 50%;
    margin-bottom: 1em;
    font-weight: $font-weight-normal;
    font-family: "Roboto", $font-family-base;
    font-size: $font-size-xs;
    letter-spacing: 0.18em;
  }
  .btn {
    padding: 0.2em;
  }
  .btn:hover {
    color: $primary;
  }
  &.hidden {
    opacity: 0;
    transform: translateX(calc(100% + map-get($spacers, 3)));
  }
}

.mainvisual {
  @include media-up(lg) {
    height: 100vh;
    display: grid;
    grid-template-rows: 60% auto;
    grid-template-columns: 36% 64%;
  }
  &__img {
    @include media-up(lg) {
      grid-column: 2;
      grid-row: 1 / span 2;
    }
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      @include media-down(lg) {
        height: auto;
      }
    }
  }

  &__head {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: map-get($spacers, 4) map-get($spacers, 3);
    @include media-down(lg) {
      padding: map-get($spacers, 3);
    }
  }

  &__logo {
    @include media-down(lg) {
      max-width: 55vw;
    }
    img {
      @include media-down(lg) {
        height: auto;
        max-width: 100%;
      }
    }
  }

  &__pickup {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: map-get($spacers, 4) map-get($spacers, 3);
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-up(lg) {
      position: absolute;
      bottom: map-get($spacers, 3);
      right: map-get($spacers, 4);
    }
    @include media-down(lg) {
      padding: 0 map-get($spacers, 3);
    }
    .reserveIcon .circle {
      fill: $white;
      transition: $transition-base;
    }
    .reserveIcon .ring {
      fill: $primary;
      animation: fa-spin 20s linear infinite reverse;
      transform-origin: center;
    }
    .reserveIcon .text {
      fill: $primary;
    }
    .icon {
      fill: $primary;
      margin-right: 1em;
    }
    .btn-outline-primary {
      padding-top: 0.8em;
      padding-bottom: 0.8em;
      width: 100%;
      text-align: center;
      border-color: tint($primary, 50%);
      &:hover,
      &:focus {
        background-color: tint($primary, 50%);
        border-color: tint($primary, 50%);
      }
    }
    .btn-icon-circle-right:after {
      background-image: svgUrlFunc(
        '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="m15.89 6.65c-.55-3.32-3.22-5.99-6.53-6.54-5.48-.9-10.15 3.77-9.25 9.24.54 3.32 3.22 5.99 6.53 6.53 5.48.9 10.14-3.77 9.24-9.24zm-5.74 1.71-2.68 2.71c-.1.1-.23.15-.36.15s-.26-.05-.36-.15c-.2-.2-.2-.52 0-.72l2.33-2.36-2.33-2.35c-.2-.2-.2-.52 0-.72s.52-.2.72 0l2.68 2.71c.2.2.2.52 0 .72z" fill="#493fa0"/></svg>'
      );
      width: 16px;
      height: 16px;
      top: calc(50% - 8px);
    }
  }
  &__reserveBtn {
    // &:hover .ring {
    //   animation-duration: 8s;
    // }
    &:hover .circle {
      fill: tint($primary, 80%);
    }
    @include media-down(lg) {
      display: none;
    }
  }
}

.pickup-item {
  position: relative;
  &:hover &__thumb {
    transform: scale(1.1);
  }
  &:before {
    position: absolute;
    content: svgUrlFunc(
      '<svg viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg"><circle cx="27" cy="27" fill="#373737" r="27"/><g fill="#fff"><path d="m13.74 23.24v2.76h-1.62v-7.84h3.06c.59 0 1.11.11 1.55.32.45.22.79.52 1.03.92s.36.85.36 1.35c0 .77-.26 1.37-.79 1.82-.53.44-1.25.67-2.18.67h-1.42zm0-1.31h1.44c.43 0 .75-.1.98-.3s.34-.49.34-.86-.11-.69-.34-.93-.54-.36-.94-.37h-1.48z"/><path d="m23.16 26h-1.62v-7.84h1.62z"/><path d="m33.01 23.39c-.06.84-.37 1.51-.93 1.99s-1.3.73-2.22.73c-1 0-1.8-.34-2.37-1.02-.58-.68-.86-1.6-.86-2.79v-.48c0-.75.13-1.42.4-1.99s.65-1.01 1.14-1.32 1.07-.46 1.72-.46c.9 0 1.63.24 2.19.73.55.48.87 1.16.96 2.04h-1.62c-.04-.51-.18-.87-.42-1.1s-.61-.34-1.11-.34c-.54 0-.94.19-1.21.58s-.41.98-.41 1.8v.59c0 .85.13 1.47.38 1.86s.66.59 1.21.59c.5 0 .87-.11 1.12-.34s.39-.58.42-1.06h1.62z"/><path d="m38.73 22.86-.84.9v2.24h-1.62v-7.84h1.62v3.55l.71-.97 2-2.58h1.99l-2.78 3.48 2.86 4.36h-1.92l-2.01-3.14z"/><path d="m25.35 32.2v5.16c0 .86-.27 1.54-.8 2.04s-1.27.75-2.2.75-1.64-.24-2.18-.73-.81-1.15-.82-2v-5.22h1.62v5.17c0 .51.12.89.37 1.12.25.24.59.35 1.02.35.91 0 1.37-.48 1.38-1.43v-5.22h1.62z"/><path d="m30.49 37.28v2.76h-1.62v-7.84h3.06c.59 0 1.11.11 1.55.32.45.22.79.52 1.03.92s.36.85.36 1.35c0 .77-.26 1.37-.79 1.82-.53.44-1.25.67-2.18.67h-1.42zm0-1.31h1.44c.43 0 .75-.1.98-.3s.34-.49.34-.86-.11-.69-.34-.93-.54-.36-.94-.37h-1.48z"/></g></svg>'
    );
    width: 54px;
    aspect-ratio: 1;
    top: -15px;
    left: -15px;
    animation: fa-beat 2000ms ease-in-out infinite;
    z-index: 1;
  }
  &__thumb {
    width: 190px;
    aspect-ratio: 1;
    object-fit: cover;
    transition: $transition-base;
    max-width: 43vw;
  }
}

.block-feature {
  background-color: #efefef;
  padding: 150px map-get($spacers, 3) map-get($spacers, 6);
  @include media-down(sm) {
    padding-top: 80px;
  }

  &__inner {
    max-width: 960px;
    margin: 0 auto;
  }
  // &__title{
  //   text-align: right;
  // }
  &__title {
    @include c-block-title;
    text-align: center;
    @include media-up(lg) {
      text-align: right;
      margin-bottom: 1.5em;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin-bottom: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include media-down(lg) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &__item {
    padding: 0;
    width: 300px;
    max-width: calc(32%);
    @include media-down(lg) {
      max-width: 100%;
      margin: 0 auto 50px;
    }
    @include media-up(lg) {
      &:nth-child(1) {
        margin-top: -35px;
      }
      &:nth-child(3) {
        margin-top: 35px;
      }
    }
    &-img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto 1em;
    }

    &-title {
      display: flex;
      flex-direction: column;
      color: $primary;
      font-size: calc(26 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      line-height: 1.75;
      letter-spacing: 0.14em;
      @include media-down(lg) {
        font-size: calc(24 / 16 * $font-size-base);
      }
      small {
        font-size: 0.625em;
        letter-spacing: 0.1em;
      }
    }
    &-desc {
      font-weight: $font-weight-bold;
      line-height: 2;
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: 0.15em;
      font-feature-settings: "palt";
      small {
        font-weight: normal;
        letter-spacing: 0.1em;
      }
    }
    &-btnwrap {
      text-align: right;
      .btn-link {
        font-size: calc(14 / 16 * $font-size-base);
        font-family: "Roboto", $font-family-base;
        font-weight: $font-weight-bold;
        letter-spacing: 0.14em;
      }
    }
  }
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: min(100vw, 1003px);
      height: auto;
      @include media-down(lg) {
        display: block;
        max-width: 100%;
      }
    }
  }
}

.block-price {
  background: url("/assets/img/price_bg@2x.png") center / 100% 100% no-repeat;
  padding: 150px map-get($spacers, 3) map-get($spacers, 6);
  
  margin-left: -1 * map-get($spacers, 4);
  margin-right: -1 * map-get($spacers, 4);
  @include media-down(md) {
    margin-left: -1 * map-get($spacers, 3);
    margin-right: -1 * map-get($spacers, 3);
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);
  }
  @include media-down(sm) {
    padding-top: 80px;
  }

  &__title {
    @include c-block-title($white);
    text-align: center;
    margin-bottom: 1.2em;
    span:before {
      opacity: 0.15;
      bottom: -0.3em;
    }
  }

  &__lead {
    color: $white;
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: calc(17 / 16 * $font-size-base);
    letter-spacing: 0.15em;
    font-feature-settings: "palt";
    line-height: 1.75;
    @include media-down(lg) {
      font-size: calc(15 / 16 * $font-size-base);
      text-align: left;
      margin-bottom: 0;
    }
    small {
      font-size: $font-size-sm;
    }
    a{
      text-decoration: underline;
    }
  }

  &__table-wrap,
  &__available {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 960px;
    margin: 2em auto;
    padding: map-get($spacers, 3) map-get($spacers, 4);
    background-color: $white;
    border-radius: 5px;
    @include media-down(lg) {
      padding: map-get($spacers, 3);
    }
  }

  &__table-wrap {
    @include media-down(lg) {
      padding-left: 9px;
      padding-right: 9px;
    }
  }

  &__available {
    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &__table {
    width: 100%;
    max-width: 800px;
    th,
    td {
      font-weight: $font-weight-bold;
      color: #53aebb;
      padding: 0.35em 0;
    }
    th {
      letter-spacing: 0.14em;
      font-size: min(calc(18 / 16 * $font-size-base), 3.2vw);
      width: 33%;
    }
    td {
      text-align: center;
      font-family: "Montserrat", $font-family-base;
      font-size: min(calc(36 / 16 * $font-size-base), 5.3vw);
      letter-spacing: 0.04em;
      small {
        font-size: 0.55em;
      }
    }
    thead th {
      text-align: center;
      padding: 0.7em;
      @include media-down(lg) {
        padding: 0 0.1em 0.5em;
      }
    }
    tbody th {
      width: 35%;
      padding-left: 2em;
      padding-right: 1em;
      @include media-down(lg) {
        width: auto;
        padding-left: 1em;
        padding-right: 0.5em;
      }
    }
    tbody tr:nth-child(odd) {
      background-color: tint($primary, 85%);
    }

    .courseName {
      @include media-down(lg) {
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__available {
    &-head {
      @include media-up(lg) {
        max-width: 256px;
      }
      @include media-down(lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.5em;
      }
    }
    &-title {
      background-color: $primary;
      color: $white;
      padding: 0.3em 0.8em;
      font-size: calc(29.5 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      letter-spacing: 0.16em;
      white-space: nowrap;
      text-align: center;
      @include media-down(lg) {
        font-size: calc(19 / 16 * $font-size-base);
      }
    }

    &-lead {
      font-size: calc(15 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      line-height: 2;
      letter-spacing: 0.15em;
      font-feature-settings: "palt";
    }

    &-body {
      @include media-up(lg) {
        margin-left: auto;
      }
      img {
        max-width: 100%;
      }
    }
  }

  &__btnwrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 38px;
    margin-bottom: calc(38px - 1em);
    .btn-icon-circle-right.btn-primary:after {
      background-image: svgUrlFunc(
        '<svg viewBox="0 0 23.05 23.05" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#{$white}" stroke-miterlimit="10"><path d="m10.54 8.41 3.77 2.99-3.77 2.99"/><circle cx="11.52" cy="11.52" r="11.02"/></g></svg>'
      );
    }
    .btn-primary:hover {
      background-color: tint($primary, 15%);
      border-color: tint($primary, 15%);
    }
    .btn {
      width: 100%;
      max-width: 248px;
      padding: 1.2em;
      margin: 0 0.7em 1em;
      font-size: calc(14 / 16 * $font-size-base);
      letter-spacing: 0.26em;
    }
  }

  &__notice {
    color: $white;
    text-align: center;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    letter-spacing: 0.15em;
    font-feature-settings: "palt";
    @include media-down(lg) {
      font-size: calc(13 / 16 * $font-size-base);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.block-step {
  margin: 170px auto 120px;
  padding: 0 map-get($spacers, 3);
  @include media-down(lg) {
    margin: 80px 0 35px;
  }
  &__title {
    @include c-block-title();
    margin-bottom: 0.8em;
    span:before {
      bottom: auto;
      top: -0.7em;
      color: #f0f0f0;
    }
    @include media-down(lg) {
      text-align: center;
      span {
        display: block;
      }
    }
  }
  &__inner {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    @include media-up(lg) {
      align-items: center;
    }
    @include media-down(lg) {
      flex-direction: column;
    }
  }
  &__img {
    @include media-up(lg) {
      max-width: 60%;
      margin-right: 75px;
    }
    @include media-down(lg) {
      margin-bottom: 65px;
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &__body {
    @include media-up(lg) {
      padding-top: 3em;
    }
    p {
      font-weight: $font-weight-bold;
      letter-spacing: 0.15em;
      line-height: 2;
      font-feature-settings: "palt";
      font-size: calc(15 / 16 * $font-size-base);
    }
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2em;
    @include media-down(lg) {
      justify-content: center;
    }
    .btn {
      width: 250px;
      max-width: 100%;
      padding-top: 1.3em;
      padding-bottom: 1.3em;
      padding-left: calc(1.2em + 23px);
      font-family: "Roboto", $font-family-base;
      font-size: calc(14 / 16 * $font-size-base);
      letter-spacing: 0.14em;
    }
  }
}

.block-reserve {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 120px 0;
  padding: 0 map-get($spacers, 3);
  @include media-down(lg) {
    margin: 35px 0 80px;
  }
  .btn {
    fill: $white;
    border-radius: 10px;
    width: 800px;
    max-width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    letter-spacing: 0.1em;
    @include media-up(lg) {
      font-size: calc(26 / 16 * $font-size-base);
    }
    &:hover {
      background-color: tint($primary, 15%);
      border-color: tint($primary, 15%);
    }
    &:after {
      @include media-up(lg) {
        width: 40px;
        height: 40px;
        top: calc(50% - 20px);
      }
    }
    .icon {
      margin-right: 0.8em;
      margin-top: -0.1em;
    }
  }
}

.block-machine {
  position: relative;
  z-index: 0;
  &:before {
    content: "";
    position: absolute;
    top: 9vw;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: #efefef;
    z-index: -1;
  }

  &__head {
    margin-bottom: 135px;
    padding: map-get($spacers, 4) map-get($spacers, 3);
    @include media-up(lg) {
      margin-right: 10vw;
      padding-top: 100px;
      padding-bottom: 80px;
      padding-left: 50vw;
      background: url("/assets/img/machine_headimg@2x.jpg") center / cover
        no-repeat;
    }
    @include media-down(lg) {
      padding-top: 0;
      padding-bottom: map-get($spacers, 3);
      margin-bottom: 0;
      &:before {
        display: block;
        content: "";
        object-fit: cover;
        width: 100vw;
        aspect-ratio: 1.74;
        background: url("/assets/img/machine_headimg@2x.jpg") 5% 50% / cover
          no-repeat;
        margin-left: -1 * map-get($spacers, 3);
        margin-bottom: 2em;
      }
    }
  }

  &__name {
    display: flex;
    flex-direction: column;
    font-size: calc(18 / 16 * $font-size-base);
    letter-spacing: 0.14em;
    font-weight: $font-weight-bold;
    @include media-down(lg) {
      text-align: center;
      align-items: center;
    }
    small {
      margin-bottom: 1.5em;
      font-size: 1em;
      font-weight: inherit;
      line-height: 1.5;
    }
    img {
      display: block;
      max-width: 53vw;
      height: auto;
    }
  }
  &__lead {
    line-height: 2;
    letter-spacing: 0.14em;
    font-size: calc(15 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    font-feature-settings: "palt";
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__body {
    max-width: calc(970px + map-get($spacers, 3) * 2);
    margin: 0 auto;
    padding: 0 map-get($spacers, 3) map-get($spacers, 6);
    @include media-down(lg) {
    }
  }

  &__title {
    color: $primary;
    font-size: calc(30 / 16 * $font-size-base);
    letter-spacing: 0.14em;
    line-height: 1.6;
    font-weight: $font-weight-bold;
    @include media-down(lg) {
      font-size: calc(20 / 16 * $font-size-base);
      text-align: center;
    }
    & + p {
      font-weight: $font-weight-bold;
      font-size: calc(15 / 16 * $font-size-base);
      line-height: 2;
      letter-spacing: 0.13em;
      font-feature-settings: "palt";
    }
  }

  &__feature {
    display: flex;
    @include media-down(lg) {
      border-top: 1px solid #b2b3b3;
      padding-top: 35px;
      flex-direction: column;
      align-items: center;
    }
    &-head {
      @include media-up(lg) {
        flex-basis: 41%;
        padding-right: 3em;
      }
    }
    &-list {
      @include make-row();
      @include media-up(lg) {
        flex-basis: 61%;
      }
    }
    &-item {
      @include make-col-ready();
      @include make-col(6);
      font-feature-settings: "palt";
      @include media-up(lg) {
        margin-top: -90px;
      }
      @include media-down(lg) {
        @include make-col(12);
      }
      dt {
        display: flex;
        justify-content: center;
      }
      dt img {
        max-width: 100%;
        height: auto;
      }
      dd {
        margin-top: 1em;
        padding: 0 1.5em;
        font-size: calc(14 / 16 * $font-size-base);
        letter-spacing: 0.1em;
        line-height: 1.78;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__notice {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 50px;
    font-size: calc(17 / 16 * $font-size-base);
    font-feature-settings: "palt";
    @include media-down(lg) {
      font-size: calc(14 / 16 * $font-size-base);
      text-align: center;
    }
    .notice {
      font-weight: $font-weight-bold;
      letter-spacing: 0.15em;
      border: 1px solid;
      padding: 0.5em 1em;
      @include media-down(lg) {
        width: 100%;
        padding-left: 0.5em;
        padding-right: 0.5em;
      }
    }
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-down(lg) {
      flex-direction: column;
    }
    &-img {
      max-width: 100%;
      height: auto;
      @include media-up(lg) {
        margin-right: 60px;
      }
      @include media-down(lg) {
        margin-bottom: 30px;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
    &-body p:last-child {
      margin-bottom: 0;
    }
  }
  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 3em;
    @include media-down(lg) {
      justify-content: center;
    }
    .btn {
      width: 100%;
      max-width: 248px;
      padding: 1.2em;
      margin: 0 0.7em 1em;
      font-size: calc(14 / 16 * $font-size-base);
      letter-spacing: 0.26em;
    }
  }
}

.block-movie {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 115px 0;
  padding: 0 map-get($spacers, 3);
  @include media-down(lg) {
    margin: 75px 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  &__link {
    transition: $transition-base;
    &:hover {
      opacity: 0.8;
    }
  }
}

.block-uservoice {
  @include media-up(lg) {
    padding: 0;
    margin-bottom: 200px;
  }
  @include media-down(lg) {
    padding: 0;
    margin-bottom: map-get($spacers, 6);
  }
  &__title {
    @include c-block-title;
    text-align: center;
    align-items: center;
    @include media-up(lg) {
      margin-bottom: 3em;
    }
    span:before {
      letter-spacing: 0;
      text-indent: 0;
      color: #f0f0f0;
    }
  }

  &__list {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin-top: map-get($spacers, 4);
    width: 100%;
    list-style: none;
    padding: 0;
    @include media-down(lg) {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: map-get($spacers, 3) * -1;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__btnwrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    padding: 0 map-get($spacers, 3);
    .btn {
      width: 250px;
      max-width: 100%;
      padding-top: 1.3em;
      padding-bottom: 1.3em;
      padding-left: calc(1.2em + 23px);
      font-family: "Roboto", $font-family-base;
      font-size: calc(14 / 16 * $font-size-base);
      letter-spacing: 0.14em;
    }
  }
}

.block-uservoice-item {
  @include list-common__item();
  position: relative;
  @include media-up(lg) {
    width: 25%;
  }
  @include media-down(lg) {
    width: calc(50% - 30px);
    margin-top: map-get($spacers, 3);
    margin-left: 15px;
    margin-right: 15px;
  }
  &:nth-child(n + 5) {
    @include media-down(lg) {
      display: none;
    }
  }
  &__body {
    @include media-up(lg) {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      background-color: fade-out($primary, 0.2);
      color: $white;
      padding: map-get($spacers, 3);
      opacity: 0;
      transition: $transition-base;
    }
  }
  &__link {
    color: $body-color;
  }
  &__link:hover &__body,
  &__link:focus &__body {
    opacity: 1;
  }

  .title {
    font-size: $font-size-base;
    @include media-up(md) {
      font-size: 1.25rem;
    }
    @include media-up(xl) {
      .is-main & {
        font-size: $font-size-base;
      }
    }
  }

  &__thumb {
    @extend .ratio;
    @extend .ratio-1x1;
    overflow: hidden;
    margin: 0;
    @include media-down(lg) {
      margin-bottom: 1em;
    }
  }
  &__thumb-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: $transition-base;
  }
  &__link:hover &__thumb-img,
  &__link:focus &__thumb-img {
    transform: scale(1.1);
  }
}

.block-salon {
  margin: 120px 0;
  padding: 0 map-get($spacers, 3);
  @include media-up(lg) {
    display: flex;
    justify-content: flex-end;
  }
  @include media-down(lg) {
    margin: 80px 0;
  }
  &__inner{
    @include media-up(lg) {
      display: grid;
      grid-template-columns: auto calc(50vw - map-get($spacers, 3));
      grid-template-rows: repeat(2, auto);
      margin-left: auto;
    }
  }
  &__title {
    @include c-block-title;
    span:before{
      color: #F0F0F0;
      letter-spacing: 0.04em;
      @include media-up(lg) {
        left: 0em;
        transform: translateX(0);
        text-indent: 0;
      }
    }
    @include media-down(lg) {
      text-align: center;
    }
  }
  &__img{
    display: flex;
    align-items: center;
    @include media-up(lg) {
      grid-column: 2;
      grid-row: 1 / span 2;
      margin: 0 -10px;
    }
    @include media-down(lg) {
      justify-content: center;
      margin: 0 -6px 65px;
    }
    img{
      max-width: calc(50% - 20px);
      height: auto;
      margin: 0 10px;
      @include media-down(lg) {
        max-width: calc(50% - 12px);
        margin: 0 6px;
      }
      &:nth-child(2n){
        @include media-up(lg) {
          transform: translateY(-40px);
        }
      }
    }
  }
  &__head{
    @include media-up(lg) {
      padding-right: 3em;
      min-width: 440px;
    }
    p{
      line-height: 2;
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: 0.15em;
      font-weight: $font-weight-bold;
      font-feature-settings: "palt";
    }
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2em;
    @include media-down(lg) {
      justify-content: center;
    }
    .btn {
      width: 250px;
      max-width: 100%;
      padding-top: 1.3em;
      padding-bottom: 1.3em;
      padding-left: calc(1.2em + 23px);
      font-family: "Roboto", $font-family-base;
      font-size: calc(14 / 16 * $font-size-base);
      letter-spacing: 0.14em;
    }
  }
}

.block-news {
  position: relative;
  margin: 60px 0;
  @include media-up(lg) {
    display: flex;
    justify-content: center;
    padding: 0 map-get($spacers, 3);
  }
  @include media-down(lg) {
    padding: 0 map-get($spacers, 3);
  }
  &__head {
    width: 100%;
    @include media-up(lg) {
      max-width: 293px;
    }
    @include media-down(lg) {
      display: flex;
      align-items: flex-start;
    }
  }
  &__list {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    @include media-up(lg) {
      max-width: 660px;
    }
  }
  &__title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: calc(52 / 16 * $font-size-base);
    font-family: "Montserrat", $font-family-base;
    letter-spacing: 0.1em;
    margin-bottom: 1em;
    @include media-down(lg) {
      font-size: calc(45 / 16 * $font-size-base);
      margin-bottom: 0.15em;
    }
    small {
      font-size: $font-size-base * 1.0625;
      letter-spacing: 0.2em;
      font-weight: $font-weight-bold;
      @include media-down(lg) {
        font-size: $font-size-base * 0.875;
      }
    }
  }
  .btn-link{
    padding-left: 0;
    font-family: 'Roboto', $font-family-base;
    font-weight: $font-weight-bold;
    font-size: calc(14 / 16 * $font-size-base);
    letter-spacing: 0.18em;
  }
  &__btnwrap{
    @include media-down(lg) {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.block-news-item {
  @include list-common__item();
  &__link {
    color: inherit;
    padding: map-get($gutters, 2) 0;
  }

  &__link {
    display: block;
    transition: $transition-base;
    &:hover,
    &:focus {
      color: $primary;
      text-decoration: none;
      .date,
      .cate,
      .title.is-new:after,
      .price,
      .price.is-regular,
      .comment {
        color: inherit;
      }
    }
  }

  .title {
    font-size: $font-size-base;
    @include media-up(lg) {
      font-size: calc(15 / 16 * $font-size-base);
    }
    &.is-new:after{
      font-size: 11px;
      color: #53AEBB;
    }
  }

  .date {
    color: $primary;
    @include media-down(md) {
      display: inline-block;
      font-size: 0.8125rem;
      margin-bottom: 0.5em;
    }
  }

  .cate {
    color: #989899;
    border-width: 0 0 0 1px;
    border-radius: 0;
    min-width: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .date-cate{
    display: flex;
    align-items: center;
  }


  & + & {
    border: solid #e1e1e1;
    border-width: 1px 0 0;
  }
}

.block-payment {
  padding: 0 map-get($spacers, 3);
  margin: 60px 0 120px;
  &__inner{
    display: flex;
    align-items: center;
    max-width: 960px;
    margin: 0 auto;
    @include media-down(lg) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__title{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: calc(15 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.1em;
    @include media-up(lg) {
      width: 200px;
    }
    @include media-down(lg) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &:before{
      content: svgUrlFunc('<svg enable-background="new 0 0 22.8 22.2" viewBox="0 0 22.8 22.2" xmlns="http://www.w3.org/2000/svg" width="23px"><g fill="#3b2816"><path d="m22.4 14.3c-.7-.6-1.6-.6-2.3 0-.6.5-3.9 2.7-3.9 2.7h-5.3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.9c.8 0 1.4-.6 1.4-1.4s-.6-1.4-1.4-1.4h-5.7c-3.6 0-4.3 1.5-4.9 2.5v5.9l6.7.7c2.5.1 3.7 0 5.5-1.5 0 0 5.3-3.9 6-4.4.5-.7.5-1.6 0-2.1z"/><path d="m0 14.2h3.1v7.5h-3.1z"/><circle cx="11.8" cy="9.1" r="2.3"/><path d="m17.2 7.7c1.1 0 2-.9 2-1.9 0-1.1-.9-2-1.9-2-1.1 0-2 .9-2 1.9-.1 1.1.8 2 1.9 2z"/><path d="m12.4 3.3c.9 0 1.7-.7 1.7-1.7s-.8-1.6-1.7-1.6-1.7.7-1.7 1.7c0 .9.7 1.6 1.7 1.6z"/></g></svg>');
      @include media-up(lg) {
        margin-bottom: 1em;
      }
      @include media-down(lg) {
        margin-right: 0.4em;
      }
    }
  }

  &__body{
    border: 0px solid $body-color;
    @include media-up(lg) {
      border-left-width: 1px;
      padding: 2em 0 2em 50px;
    }
    @include media-down(lg) {
      border-top-width: 1px;
      padding: 1em;
    }
  }
  &__item{
    &:last-child{
      margin-bottom: 0;
    }
    dt {
      text-indent: -1.2em;
      padding-left: 1.2em;
      &:before{
        display: inline-block;
        content: '\f0c8';
        font-family: 'Font Awesome 6 Free';
        width: 1.2em;
        text-indent: 0;
      }
    }
    dd{
      margin-bottom: 0;
      margin-top: 1em;
      @include media-up(lg) {
        padding-left: 2.5em;
      }
      @include media-down(lg) {
        padding-left: 1.2em;
      }
      img{
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }
}