@keyframes up_down_line {
  0% {
    transform: translateY( 2px );
  }

  50% {
    transform: translateY( 14px );
  }
}


.rwd002-pagetop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -16px;
  font-size: $font-size-xs;
  font-family: "Roboto", $font-family-sans-serif;
  color: $white;
  text-decoration: none;
  letter-spacing: 0.1em;
  transform-origin: bottom;

  @include media-up(lg) {
    right: map-get($spacers, 5);
  }
  @include media-down(lg) {
    right: 50%;
    transform: translateX(50%);
  }
  .icon {
    margin-bottom: 5px;
    fill: #C5C6C6;
    & use {
      transform: translateY( 2px );
    }
  }
  &:hover {
    text-decoration: none;
    color: $white;
    .icon use {
      animation: up_down_line 1.2s infinite ease-in-out;
    }
  }
  &__wrap {
    width: 100%;
    z-index: 10;
    transition: $transition-base;
    @include media-up(lg) {
      position: absolute;
      margin: 0 auto;
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);
    }
    @include media-down(lg) {
      position: relative;
    }
    &.hidden {
      transform: translateY(200px);
      opacity: 0;
    }
  }
}

.rwd002-footer {
  position: relative;
  background-color: #EFEFEF;
  @include media-up(md) {
    padding: 0 map-get($spacers, 5) map-get($spacers, 3);
  }
  @include media-down(md) {
    padding: 0 map-get($spacers, 3) map-get($spacers, 3);
  }

  &__inner {
    max-width: 950px;
    margin: 0 auto;
    @include media-up(lg) {
      padding-top: map-get($spacers, 5);
      padding-bottom: map-get($spacers, 5);
      display: grid;
      grid-template-rows: repeat(2, auto);
      grid-template-columns: 1fr auto;
    }
    @include media-down(lg) {
      padding-top: map-get($spacers, 4);
      padding-bottom: map-get($spacers, 4);
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__info {
    font-size: calc(13 / 16 * $font-size-base);
    letter-spacing: 0.16em;
    font-feature-settings: "palt";
    @include media-up(lg) {
      flex-grow: 1;
      padding-right: map-get($spacers, 3);
      grid-row: 1;
    }
    @include media-down(lg) {
      margin-bottom: 2em;
    }
    .tel,
    .sns{
      font-family: 'Montserrat', $font-family-base;
      font-size: $font-size-base;
      font-weight: 500;
      margin: 1em 0;
      @include media-down(lg) {
        display: block;
        text-align: center;
        margin: 0.5em 0;
      }
      span{
        margin-right: 1em;
      }
      [class*="fa-"]{
        margin-right: 0.5em;
        &:last-child{
          margin-right: 0;
        }
      }
      .fa-brands{
        &:hover,&:focus{
          text-decoration: none;
          color: $primary;
        }
      }
    }
    .tel{
      @include media-up(lg) {
        a{
          pointer-events: none;
        }
      }
    }
    .opentime{
      @include media-down(lg) {
        display: block;
        text-align: center;
        margin-bottom: 2em;
      }
    }
  }
  &__companyName {
    display: flex;
    flex-direction: column;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    letter-spacing: 0.08em;
    font-feature-settings: "palt";
    line-height: 1.5;
    @include media-down(lg) {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  &__address {
    margin-bottom: 0;
    @include media-down(lg) {
      text-align: center;
    }
    .zip{
      @include media-down(lg) {
        display: block;
      }
    }
  }

  &__copyright,
  &__login {
    display: block;
    text-align: center;
    font-size: $font-size-xxs;
    letter-spacing: 0.14em;
    text-indent: 0.14em;
    color: tint($dark, 30%);
    font-feature-settings: "palt";
  }

  &__copyright {
    @include media-down(md) {
      margin-left: -1.3em;
      margin-right: -1.3em;
    }
  }

  &__login {
    margin-top: 1em;
  }
}

.rwd002-footer-head{
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('/assets/img/price_bg@2x.png') center top / 100% no-repeat;
  padding: map-get($spacers, 3);
  color: $white;
  @include media-up(md) {
    min-height: 209px;
    margin: 0 -1 * map-get($spacers, 5);
  }
  @include media-down(lg) {
    flex-direction: column;
    text-align: center;
    margin: 0 -1 * map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);
  }
  &__title{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: $font-weight-normal;
    font-family: 'Montserrat', $font-family-base;
    line-height: 2;
    letter-spacing: 0.18em;
    font-size: calc(19 / 16 * $font-size-base);
    @include media-up(lg) {
      padding-right: map-get($spacers, 4);
      margin: 0;
    }
    @include media-down(lg) {
      margin-top: 30px;
      margin-bottom: 1em;
    }
    strong{
      font-weight: 600;
      font-size: 1.1em;
      letter-spacing: 0.1em;
    }
  }
  &__btnwrap{
    max-width: 100%;
    @include media-up(lg) {
      padding-left: map-get($spacers, 4);
      border-left: 1px solid #C5C6C6;
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 344px;
      max-width: 100%;
      color: $primary;
      fill: $primary;
      padding-top: 1em;
      padding-bottom: 1em;
      letter-spacing: 0.14em;
      &:hover,&:focus{
        background-color: tint($primary, 85%);
        border-color: tint($primary, 85%);
      }
      @include media-up(lg) {
        height: 100px;
      }
      @include media-down(lg) {
        font-size: calc(17.5 / 16 * $font-size-base);
      }
    }
    .icon{
      margin-right: 1.2em;
      @include media-down(lg) {
        width: 1.2em;
      }
    }
  }
}

.rwd002-footerMap{
  @include media-up(lg) {
    grid-row: 1 / span 2;
  }
  &__iframe{
    width: 482px;
    max-width: 100%;
    aspect-ratio: 1.47;
    overflow: hidden;
    border: 1px solid #C5C6C6;
    position: relative;
    z-index: 0;
    iframe{
      position: absolute;
      height: calc(100% + 300px);
      margin-top: -150px;
      filter: grayscale(100%);
      z-index: -1;
      pointer-events: none;
    }
  }
  &__btnwrap{
    display: flex;
    justify-content: flex-end;
    @include media-down(lg) {
      justify-content: center;
    }
    .btn{
      font-family: 'Roboto', $font-family-base;
      letter-spacing: 0.16em;
      padding-left: 0;
      padding-right: 0;
    }
    [class*="fa-"]{
      margin-right: 0.3em;
    }
  }
}

.rwd002-footerNav {
  padding-top: 1em;
  margin-top: 0.5em;
  border-top: 1px solid #C5C6C6;
  max-width: 337px;
  @include media-down(lg) {
    margin-top: 2em;
  }
  &__list,
  &__sublist {
    list-style: none;
    padding: 0;
    margin: 0;
    @include media-down(lg) {
      margin-right: -1.5em;
      padding-left: map-get($spacers, 2);
    }
  }
  &__list {
    font-size: calc(13 / 16 * $font-size-base);
    display: flex;
    flex-wrap: wrap;
  }
  &__sublist {
    font-size: $font-size-xs;
    color: darken($white, 40%);
    display: flex;
    flex-wrap: wrap;
  }
}

.rwd002-footerNav-item {
  display: flex;
  flex-wrap: wrap;
  margin-right: 2em;
  &__link {
    position: relative;
    display: inline-block;
    padding: 0.5em 0;
    letter-spacing: 0.18em;
    font-feature-settings: "palt";
    @include media-down(lg) {
      letter-spacing: 0.1em;
    }
    &:hover,
    &:focus {
      color: $dark;
    }
  }
}

.rwd002-footerNav-subitem {
  margin-right: 1.5em;
  &__link {
    display: inline-block;
    padding: 0.5em 0;
    margin-bottom: 0.5em;
    letter-spacing: 0.16em;
    transition: $transition-base;
    @include media-down(lg) {
      letter-spacing: 0.1em;
    }
    &:hover,
    &:focus {
      color: $dark;
    }
    @include media-down(lg) {
      margin-bottom: 0;
    }
  }
}

.rwd002-footer-subnav {
  @include media-up(md) {
    display: none;
  }
  &__tgl {
    @extend .btn;

    &:focus {
      box-shadow: none;
    }

    @include media-up(md) {
      display: none;
    }
    @include media-down(md) {
      position: relative;
      flex-basis: 40px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 5px);
        width: 10px;
        height: 1px;
        background-color: darken($white, 50%);
        transition: transform 0.2s ease-in-out;
      }

      &:after {
        transform: rotate(-90deg);
      }

      &.is-active {
        &:before {
          transform: rotate(180deg);
        }
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
  &__list {
    list-style: none;
    @include media-down(md) {
      padding-left: 1.7em;
      padding-bottom: 1em;
    }
  }
}

.rwd002-footer-subnav-item {
  &__link {
    @include media-down(md) {
      display: block;
      padding: 0.2em 0;
    }
  }
}
