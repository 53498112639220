@mixin c-module-title() {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.75em;
  font-weight: normal;
  letter-spacing: 0.22em;
  text-indent: 0.22em;
  font-size: $h2-font-size;
  @include media-down(lg) {
    font-size: 1.875rem;
  }
  @include media-down(md) {
    font-size: $h4-font-size;
  }

  span {
    font-family: "Roboto", $font-family-sans-serif;
    font-weight: $font-weight-normal;
  }

  &:after{
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    width: 82px;
    height: 1px;
    margin: auto;
    background: $primary;
  }
}

@mixin c-block-title($theme: $body-color){
  font-size: calc(30 / 16 * $font-size-base);
  font-weight: $font-weight-bold;
  letter-spacing: 0.14em;
  text-indent: 0.14em;
  margin-bottom: 1em;
  color: $theme;
  @include media-down(lg) {
    font-size: calc(28 / 16 * $font-size-base);
  }
  span{
    position: relative;
    z-index: 0;
  }
  span:before{
    position: absolute;
    content: attr(data-largetext);
    font-family: 'Montserrat', $font-family-base;
    font-weight: 700;
    font-size: min( calc(100 / 16 * $font-size-base), 15vw);
    letter-spacing: 0.1em;
    text-indent: 0.1em;
    color: #E6E6E6;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    white-space: nowrap;
  }
}